<template>
  <nav class="sidebar">
    <div class="sidebar-inner-scroll">
      <h2 class="sidebar__title">एकीकृत सूचना/जानकारी</h2>
      <vue-custom-scrollbar class="scroll-area">
      <ul class="sidebar-menu">
        <li class="sidebar-menu__items">
          <router-link class="sidebar-menu__links" :class="{ 'router-link-active': checkAboutTabActive }" to="/" exact>
            <unicon name="comment-alt-info"></unicon>मेल्लेख गाउँपालिकाकाे परिचय
          </router-link>
        </li>
        <li class="sidebar-menu__items">
          <router-link class="sidebar-menu__links" :class="{ 'router-link-active': checkActive }" to="/citizen-charter">
            <unicon name="book-reader"></unicon>नागरिक वडापत्र
          </router-link>
        </li>
        <li class="sidebar-menu__items">
          <router-link class="sidebar-menu__links" to="/policy">
            <unicon name="file-landscape-alt"></unicon>महत्वपूर्ण नीतिहरु
          </router-link>
        </li>
        <li class="sidebar-menu__items">
          <router-link class="sidebar-menu__links" to="/decisions">
            <unicon name="file-alt"></unicon>गाउँसभाका निर्णयहरु
          </router-link>
        </li>
        <li class="sidebar-menu__items">
          <router-link class="sidebar-menu__links" to="/point-of-interest">
            <unicon name="location-pin-alt"></unicon>आकर्षणका केन्द्रकाे प्रकार नक्शा
          </router-link>
        </li>
        <li class="sidebar-menu__items">
          <router-link class="sidebar-menu__links" :class="{ 'router-link-active': checkBudgetTabActive }" to="/budget">
            <unicon name="coins"></unicon>वार्षिक विकास कार्यक्रम
          </router-link>
        </li>
        <li class="sidebar-menu__items">
          <router-link class="sidebar-menu__links" to="/ims">
            <unicon name="map"></unicon>आयाेजनाहरूको नक्सामा आधारित वितरण
          </router-link>
        </li>
      </ul>
      </vue-custom-scrollbar>
    </div>
    <!-- <div class="disclaimer">
      <unicon name="exclamation-circle"></unicon>
      मेल्लेख गाउँपालिकाका विभिन्न प्रणालीहरुमा रहेका आँकडालाई संग्रहित गरि यस प्रणालीमार्फत् प्रस्तुत गरिएकाे छ।
    </div> -->
  </nav>
</template>

<script>
import vueCustomScrollbar from 'vue-custom-scrollbar';
import "vue-custom-scrollbar/dist/vueScrollbar.css";
export default {
  components: {
    vueCustomScrollbar
  },
  computed: {
    checkActive() {
      if (this.$route.name === "Citizen-charter-detail") return true;

      return false;
    },
    checkBudgetTabActive () {
      let routeName = this.$route.name;
      if (
        routeName === "Program-list" || 
        routeName === "Program-detail"
      ) return true;
      return false;
    },
    checkAboutTabActive () {
      let routeName = this.$route.name;
      if (
        routeName === "achievement-detail" || 
        routeName === "upcoming-programs" || 
        routeName === "upcoming-program-detail" ||
        routeName === "introduction" ||
        routeName === "history" ||
        routeName === "naming" 
      ) return true;
      return false;
    }
  }
};
</script>

<style lang='scss'>
  .sidebar {
    width: 800px;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    background: $neutrals-midnight;
    z-index: 2;
    /* overflow-y: auto; */
    
    &__title {
      text-align: center;
      margin: 219px 0 85px;

      @media screen and (max-width: 3839px) {
        margin: 120px 0 70px;
      }

      @media screen and (max-width: 3199px) {
        margin: 82px 0 48px;
        font-size: 20px;
      }
    }

    @media screen and (max-width: 3839px) {
      width: 670px;
    }

    @media screen and (max-width: 3199px) {
      width: 320px;
    }

    @media screen and (max-width: 1800px) {
      width: 285px;
    }
  }

  .sidebar-inner-scroll {
    padding: 50px;

    .ps-container {
      height: calc(100vh - 300px);
    }

    @media screen and (max-width: 3839px) {
      padding: 40px;
    }

    @media screen and (max-width: 3199px) {
      padding: 18px;
      .ps-container {
        height: calc(100vh - 200px);
        .ps__thumb-y {
          right: 0!important;
        }
      }
    }

  }

.sidebar-menu {
  &__items {
    margin-bottom: 24px;

    @media screen and (max-width: 3199px) {
      margin-bottom: 8px;
    }
  }

  &__links {
    display: flex;
    align-items: center;
    padding: 56px 0 56px 40px;
    color: $neutrals-ash;
    border-radius: 20px;

    @media screen and (max-width: 3200px) {
      padding: 48px 0 48px 24px;
    }

    @media screen and (max-width: 3199px) {
      padding: 20px 0 20px 18px;
      font-size: 16px;
      border-radius: 8px;
    }

    @media screen and (max-width: 1800px) {
      font-size: 14px;
    }    

    &.router-link-active {
      @include bg-primary-gradient-eggplant;
      color: $neutrals-white;

      &:hover {
        @include bg-primary-gradient-eggplant;
      }
    }

    &:hover {
      background: $neutrals-charcoal;
      color: $neutrals-white;
    }

    &:active {
      @include bg-primary-gradient-eggplant;
      color: $neutrals-white;
    }

    svg {
      fill: $neutrals-ash;
      width: 48px;
      height: 48px;
      margin-right: 20px;

      @media screen and (max-width: 3199px) {
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }
    }
  }
}

.disclaimer {
  align-items: flex-start;
  background: $neutrals-charcoal;
  bottom: 0;
  display: flex;
  font-size: 38px;
  left: 0;
  padding: 60px 90px;
  position: absolute;
  right: 0;
  svg {
    margin-right: 24px;
  }
}
</style>
